.table-container {
  @apply max-w-full overflow-x-auto;
}

.table-default {
  @apply w-full max-w-full;
  @apply text-left;

  th,td {
    @apply py-2 pr-2 first:pl-2 @lg/table:pr-4 @lg/table:first:pl-4;
  }
  th, th button {
   @apply uppercase text-sm
  }
  tbody {
    tr {
      @apply odd:bg-gray-100;
    }
  }
}
