.ql-editor {
  @apply resize-y;
  @apply min-h-44;
}

.quill {

  & .ql-snow a {
    @apply text-secondary;
  }

  & .ql-toolbar.ql-snow {
    @apply rounded-t;
    @apply border-gray-900;
    @apply border-b-gray-150;
    @apply font-condensed;
  }
  & .ql-container.ql-snow {
    @apply border-gray-900;
    @apply rounded-b;
    @apply font-condensed;
    @apply text-base;
  }

  & .ql-snow .ql-picker {
    @apply text-gray-900;
    @apply text-base;
  }
  & .ql-formats button {
    @apply text-gray-900;
  }
  & .ql-picker-label.ql-picker-label.ql-picker-label:hover,
  & .ql-formats.ql-formats button:hover,
  & .ql-snow.ql-toolbar .ql-picker-label.ql-active {
    @apply text-secondary;
  }
  & .ql-formats svg .ql-stroke {
    stroke: currentColor !important;
  }
  
  & .ql-formats svg .ql-fill {
    fill: currentColor !important; 
  }

  & .ql-toolbar.ql-snow .ql-picker-options.ql-picker-options.ql-picker-options,
  & .ql-snow .ql-tooltip.ql-tooltip {
    @apply bg-gray-50;
    @apply text-gray-900;
    @apply border-0;
    @apply rounded;
    @apply shadow-control-overlay;
    z-index: 2;
  }

  & .ql-snow .ql-tooltip input[type=text] {
    @apply bg-transparent rounded-sm border-gray-900 text-gray-900;
  }

  & .quill .ql-snow .ql-tooltip.ql-tooltip {
    @apply flex text-sm;
  }

  & .ql-snow .ql-tooltip::before {
    content: "Link:"
  }

  & .ql-snow .ql-tooltip[data-mode="link"]::before {
    content: "Link:"
  }
  
  & .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    content: "Ok";
    width: auto;
    height: auto;
    background-image: none;
  }

  & .ql-snow .ql-tooltip a.ql-action {
    @apply p-1;
  }

  & .ql-snow .ql-tooltip a.ql-action::after {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg fill='%234d98c9' fill-rule='evenodd'%3E%3Cpath d='m18.7 1.3 4 4a1 1 0 0 1 0 1.4l-12 12a1 1 0 0 1-.7.3H6a1 1 0 0 1-1-1v-4a1 1 0 0 1 .3-.7l12-12a1 1 0 0 1 1.4 0ZM18 3.4l-11 11V17h2.59l11-11L18 3.41Z'/%3E%3Cpath d='M11 4a1 1 0 0 1-1 1H3.5a.5.5 0 0 0-.5.5v15c0 .28.22.5.5.5h15a.5.5 0 0 0 .5-.5V14a1 1 0 0 1 2 0v6.5a2.5 2.5 0 0 1-2.5 2.5h-15A2.5 2.5 0 0 1 1 20.5v-15A2.5 2.5 0 0 1 3.5 3H10a1 1 0 0 1 1 1Z' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    @apply inline-block;
    @apply w-4 h-4;
    @apply border-0;
  }

  & .ql-snow .ql-tooltip a.ql-remove {
    @apply p-1;
  }
  & .ql-snow .ql-tooltip a.ql-remove::before {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%234d98c9' fill-rule='evenodd'%3E%3Cpath d='M3 7h18a1 1 0 0 0 0-2H3a1 1 0 1 0 0 2Z'/%3E%3Cpath d='M18 6v14a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V6a1 1 0 1 0-2 0v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V6a1 1 0 0 0-2 0Z'/%3E%3Cpath d='M9 6V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v2a1 1 0 0 0 2 0V4a3 3 0 0 0-3-3h-4a3 3 0 0 0-3 3v2a1 1 0 1 0 2 0ZM9 11v6a1 1 0 0 0 2 0v-6a1 1 0 1 0-2 0ZM13 11v6a1 1 0 0 0 2 0v-6a1 1 0 0 0-2 0Z'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    @apply inline-block;
    @apply w-4 h-4;
  }
}

  

.rich-text,
.ql-editor {

  & ul,
  & ol,
  & p,
  & h1,
  & h2,
  & h3, {
    &:not(:first-child) {
      @apply mt-3
    }
    &:not(:last-child) {
      @apply mb-3
    }
  }

  & ul,
  & ol {
    @apply pl-6;
  }
  & ul {
    @apply list-disc;
  }
  & ol {
    @apply list-decimal;
  }


  & h1 {
    @apply text-xl;
  }
  & h2 {
    @apply text-lg;
  }
  & h3 {
    @apply text-base;
  }

  & a {
    @apply underline;
    @apply text-secondary;
    &:hover {
      @apply text-gray-900;
      text-underline-offset: 3px;
    }
  }
}
