:root {
  --text-xs: calc(var(--text-base) / 1.2 / 1.2);
  --text-xs--lh: calc(var(--text-xs) * 1.4);
  
  --text-sm: calc(var(--text-base) / 1.2);
  --text-sm--lh: calc(var(--text-sm) * 1.4);
  
  /* --text-base-sm: calc(var(--text-base) / 1.15);
  --text-base-sm--lh: calc(var(--text-base-sm) * 1.4); */

  --text-base: 1.0625rem;
  --text-base--lh: calc(var(--text-base) * 1.4);

  --text-lg: calc(var(--text-base) * 1.25);
  --text-lg--lh: calc(var(--text-lg) * 1.2);

  --text-xl: calc(var(--text-base) * 1.25 * 1.25);
  --text-xl--lh: calc(var(--text-xl) * 1.2);

  --text-2xl: calc(var(--text-base) * 1.25 * 1.25 * 1.25);
  --text-2xl--lh: calc(var(--text-2xl) * 1.17);

  --text-3xl: calc(var(--text-base) * 1.25 * 1.25 * 1.25 * 1.25);
  --text-3xl--lh: calc(var(--text-3xl) * 1.15);

  --text-4xl: calc(var(--text-base) * 1.25 * 1.25 * 1.25 * 1.25 * 1.25);
  --text-4xl--lh: calc(var(--text-4xl) * 1.1);
}

body {
  @apply text-base;
}

@media screen and (min-width: 768px) {
  :root {
    /* control all font sizes by changing the base */
    /* --text-base: 2rem; */
  }
}

.font-bold {
  font-family: var(--font-ringside-compressed);
}

.font-semibold {
  font-family: var(--font-ringside-condensed);
}

.font-bold,
.font-compressed.font-bold {
  font-feature-settings: "ss04";
}


body .font-compressed[class*="font-semibold"] {
  /* THIS IS AN INVALID COMBINATION */
  /* USE EITHER OF .font-semibold .font-condensed */
  /* OR .font-bold .font-compressed */
  color: magenta;
}
