:root {
  --accent: #36a024;
  --accent-variant: #2d851f;
  --accent-variant-strong: #1c5012;
  --muted: #747577;
  --muted-variant: #626365;
  --muted-variant-strong: #505153;
  --gray-50: 255 255 255; /* #fff; */
  --gray-100: 243 243 243; /* #F3F3F3; */
  --gray-150: 228 228 228; /* #e4e4e4; */
  --gray-200: 116 117 119; /* #747577; */
  --gray-300: 54 53 53; /* #363535; */
  --gray-400: 34 34 34; /*#222222;*/
  --gray-900: 7 7 7; /* #070707;*/
  --highlight: #fee733;
  --highlight-variant: #ffff7f;
  --primary: #cc0000;
  --primary-variant: #ae0000;
  --primary-variant-strong: #440000;
  --secondary: #4d98c9;
  --secondary-darker: #3580B0;
  --secondary-variant: #dff2fd;
  --surface: #fff;
  
  --tap-highlight-color: rgba(116, 117, 119, 0.15);
  
  --white-forced: #fff;
  --black-forced: #000;
  --primary-forced: #cc0000;
  --primary-variant-forced: #ae0000;
  
  --gray-150-forced: 228 228 228; /* #e4e4e4; */
  --gray-400-forced: 51 51 51; /* #333;*/
  --gray-900-forced: 7 7 7; /* #070707;*/

  --warning: #c00;

  --green: 54 160 36;
  /* --maybe: #FCD12A; */
  --accepted: 54 160 36;
  --maybe: 77 152 201;
  --declined: 204 0 0;
  /* --maybe: var(--gray-900); */
}

@media (prefers-color-scheme: dark) {
  :root {
    --accent: #36a024;
    --accent-variant: #2d851f;
    --accent-variant-strong: #1c5012;
    --muted: #747577;
    --muted-variant: #626365;
    --muted-variant-strong: #505153;
    --gray-50: 5 5 5; /* #050505; */
    --gray-100: 18 18 18; /* #121212; */
    --gray-150: 24 24 24; /* #181818; */
    --gray-200: 138 138 138; /*#8a8a8a;*/
    --gray-300: 205 205 205; /* #cdcdcd;*/
    --gray-400: 241 241 241; /* #f1f1f1;*/
    --gray-900: 251 251 251; /*#fbfbfb;*/
    --highlight: #ffd60a;
    --highlight-variant: #ffff88;
    --primary: #f02020;
    --primary-variant: #e51b1b;
    --primary-variant-strong: #6e1616;
    --secondary: #4d98c9;
    --secondary-variant: #181818; /*TODO: Fix*/
    --surface: #222;

    --warning: #f02020;

    --green: 54 160 36;

    --accepted: 54 160 36;
    --maybe: 77 152 201;
    --declined: 240 32 32;
  }
}
