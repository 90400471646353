@layer components {
  .content-switch {
    @apply flex justify-center lg:justify-start;
    @apply mr-px;
  }

  .content-switch__button {
    @apply -mr-px relative py-1.5 -my-1.5;
    &:first-child {
      & .content-switch__button-content {
        @apply rounded-l;
      }
    }
    &:last-child {
      & .content-switch__button-content {
        @apply rounded-r;
      }
    }
  }
  .content-switch__button-content {
    @apply border border-gray-200;
    @apply bg-surface;
    @apply px-3 py-1;
    @apply relative;
    @apply font-bold;
    @apply uppercase;
    @apply text-muted;
    @apply overflow-hidden;
    @apply duration-fast;
    &:after {
      content: "";
      @apply block;
      @apply absolute bottom-0 right-0 left-0;
      @apply h-0.5;
      @apply bg-team;
      @apply opacity-0;
      @apply duration-fast;
    }
  }

  /* mobile tap */
  .content-switch__button:not([disabled]):active {
    & .content-switch__button-content {
      @apply bg-gray-200;
      @apply text-gray-50;
    }
  }

  /* desktop */
  @media (hover:hover) {
    .content-switch__button:not([disabled]):hover {
      & .content-switch__button-content {
        @apply text-gray-900;
        @apply border-gray-900;
        &:after {
          @apply opacity-100;
          @apply bg-gray-900;
        }
      }
    }
    .content-switch__button:not([disabled]):active {
      & .content-switch__button-content {
        @apply bg-gray-900;
        @apply text-gray-50;
      }
    }
  }

  /* keyboard navigation */
  .content-switch__button:not([disabled]):focus-visible {
    /* @apply scale-110; */
    & .content-switch__button-content {
      @apply bg-gray-150;
    }
  }
    

  .content-switch__button--active {
    @apply z-10;
    & .content-switch__button-content {
      @apply text-gray-900;
      @apply border-gray-900;
      &:after {
        @apply opacity-100;
      }
    }

  }
  
}

