.onboarding-slider {
  @apply h-[100dvh];
  
  & .onboarding-slider-container {
    @apply relative;
    @apply bg-gray-50;
    @apply h-full;
  } 

  @media screen and (min-width: 30em) {
    @apply flex flex-col items-center justify-center ;

    & .onboarding-slider-container {
      width: 30rem;
      max-height: 51rem;
      @apply overflow-hidden;
      @apply rounded-lg;
      @apply border border-gray-50;
    }
  }

  & .slick-slider,
  & .slick-list,
  & .slick-track,
  & .slick-slide,
  & .slick-slide > div {
    height: 100%
  }

  & .slick-slide > div {
    position: relative;
  }


  & .slick-dots {
    position: absolute;
    bottom: 75px;
  }

  & .slick-slide {
    opacity: 1;
    transition: opacity .3s;
    &:not(.slick-active) {
      opacity: 0;
    }
  }
}


.onboarding-screenshot {
  -webkit-mask-image: linear-gradient(to top, rgba(0,0,0,0.3) 0%, black 30%);
  mask-image: linear-gradient(to top, rgba(0,0,0,0.3) 0%, black 30%);  
}


@media screen and (min-height: 50em) {
  .onboarding-slider__text-content {
    @apply pb-[8.5rem];
  }
  .onboarding-slider h2.mt-2.text-balance {
    @apply mt-4;
  }
}

@media screen and (min-width: 25em) {
  .onboarding-slider__text-content {
    @apply pb-[6rem];
  }
  .onboarding-slider .slick-dots {
    bottom: 33px;
  }

  .onboarding-slider h2.mt-2.text-balance {
    @apply mt-4;
  }
}
