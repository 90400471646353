@layer components {
  .mobile-nav-button {
    @apply rounded-full;
    @apply h-11 px-3;
    @apply flex items-center justify-center;
    @apply duration-fast;
    @apply no-underline;
    @apply select-none;
    -webkit-tap-highlight-color: transparent;
    @apply text-lg;
    @screen lg {
      @apply text-base;
    }
  }
  
  .mobile-nav-button--secondary {
    @apply text-secondary;
  }
  
  .mobile-nav-button svg {
    width: 1em;
    height: 1em;
  }
  
  /* mobile tap */
  .mobile-nav-button:not([disabled]):active {
    @apply scale-90;
    @apply bg-gray-200;
    @apply text-gray-50;
  }
  
  /* desktop */
  @media (hover:hover) {
    .mobile-nav-button:not([disabled]):hover {
      @apply scale-110;
      @apply bg-gray-100;
    }
    .mobile-nav-button:not([disabled]):active {
      @apply scale-90;
      @apply bg-gray-200;
      @apply text-gray-50;
    }
  }
  
  /* keyboard navigation */
  .mobile-nav-button:not([disabled]):focus-visible {
    @apply scale-110;
  }
  
  
  
  /* ON PRIMARY */
  
  .mobile-nav-button--on-primary:not([disabled]):active {
    @apply bg-primary-variant-strong;
  }
  
  /* desktop */
  @media (hover:hover) {
    .mobile-nav-button--on-primary:not([disabled]):hover {
      @apply bg-primary-variant;
    }
    .mobile-nav-button--on-primary:not([disabled]):active {
      @apply bg-primary-variant-strong;
    }
  }
}
