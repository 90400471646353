html {
  min-width: 360px;
  min-height: calc(100dvh + env(safe-area-inset-top));
}

a, button, div {
  -webkit-tap-highlight-color: var(--tap-highlight-color);
}

.red-bar {
  position: fixed;
  top: -10px;
  left: 0;
  right: 0;
}

.page-pl {
  @apply pl-max-5-or-safe-area-inset-left;
}
.page-pr {
  @apply pr-max-5-or-safe-area-inset-left;
}

@screen lg {
  .page-pl,
  .lg\:page-pl {
    @apply pl-max-6-or-safe-area-inset-left;
  }
  .page-pr,
  .lg\:page-pr {
    @apply pr-max-6-or-safe-area-inset-left;
  }
}
@screen xl {
  .page-pl,
  .lg\:page-pl {
    @apply pl-max-10-or-safe-area-inset-left;
  }
  .page-pr,
  .lg\:page-pr {
    @apply pr-max-10-or-safe-area-inset-left;
  }
}


.content-tab-bar__link {
  @apply duration-fast text-gray-900;
  -webkit-tap-highlight-color: transparent;
  @apply rounded;
}

.content-tab-bar__icon {
  @apply rounded-full;
  @apply p-2;
  @apply duration-fast;
}

.content-tab-bar__icon svg {
  @apply w-6 h-6;
}

.content-tab-bar__link:active {
  @apply opacity-100;
}

.content-tab-bar__link:active .content-tab-bar__icon {
  @apply scale-90;
  @apply bg-gray-200;
  @apply text-gray-50;
}

@media (hover:hover) {
  .content-tab-bar__link:hover {
    @apply opacity-100;
  }
  .content-tab-bar__link:hover .content-tab-bar__icon {
    @apply scale-110;
    @apply bg-gray-100;
  }
  .content-tab-bar__link:active .content-tab-bar__icon {
    @apply scale-90;
    @apply bg-gray-200;
    @apply text-gray-50;
  }
}

.content-tab-bar__link:focus-visible {
  @apply opacity-100;
}
.content-tab-bar__link:focus-visible .content-tab-bar__icon {
  @apply scale-110;
}
