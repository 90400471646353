@layer components {
  .fieldset {
    &:disabled {
      @apply opacity-30;
    }
  }
  .input-checkbox {
    &:checked {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M21 5 10.29 19 4 12.83'/%3E%3C/svg%3E");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 90%;
    }
  }
}
