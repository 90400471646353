.loader {
  @apply animate-pulse-load;
}

.loader__surface {
  @apply bg-gray-150;
}


.mask-v {
  mask-image: linear-gradient(black 0, transparent 100%);
}
