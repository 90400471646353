@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "./_colors";
@import "./_layout";
@import "./_typography";

@import "./components/_button";
@import "./components/_card";
@import "./components/_content-switch";
@import "./components/_interactive-cell";
@import "./components/_forms";
@import "./components/_loader";
@import "./components/_loading";
@import "./components/_mobile-nav-button";
@import "./components/_modal";
@import "./components/_nav";
@import "./components/_onboarding-slider";
@import "./components/_r-select";
@import "./components/_rich-text";
@import "./components/_react-datetime-picker";
@import "./components/_tab-bar";
@import "./components/_table";

@import "./_utilities";
