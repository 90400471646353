.react-datetime-picker {
}

.react-datetime-picker__inputGroup__input.react-datetime-picker__inputGroup__input:invalid {
  background: transparent;
}

.react-datetime-picker__wrapper.react-datetime-picker__wrapper {
  @apply px-3 py-2;
  @apply border border-gray-900 bg-transparent rounded;
}

.react-datetime-picker__inputGroup {
}


.react-datetime-picker__inputGroup__input {
  @apply focus:outline-none focus:ring-1 focus:ring-secondary focus:border-secondary;
  @apply border-none;
}

.react-datetime-picker__button.react-datetime-picker__button {
  @apply py-2 -my-2;
}

.react-datetime-picker__button svg {
  @apply w-4 h-4;
}


.react-datetime-picker__calendar


.react-calendar {
  @apply bg-gray-50 rounded;
  @apply shadow-control-overlay;
  border: none;
  @apply mt-2;
}

.react-calendar__navigation {
  @apply flex items-center justify-center gap-2 p-2;
}


.react-calendar__navigation__label {
  @apply font-semibold py-1;
}

.react-calendar__navigation__arrow {
  @apply w-8 py-1;
  font-family: Verdana, sans-serif;
}


.react-calendar button,
.react-calendar__navigation__label {
  @apply rounded;
  @apply duration-fast;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

/* mobile */
.react-calendar button:not([disabled]):active
.react-calendar__navigation__label:not([disabled]):active {
  @apply scale-[98%];
  @apply bg-gray-200;
  @apply text-gray-50;
}

/* desktop */
@media (hover:hover) {
  .react-calendar button:not([disabled]):hover,
  .react-calendar__navigation__label:not([disabled]):hover {
    @apply scale-[104%];
    @apply text-gray-900;
    @apply bg-gray-100;
    @apply font-semibold cursor-pointer;
    @apply border-gray-900;
  }
  .react-calendar button:not([disabled]):active,
  .react-calendar__navigation__label:not([disabled]):active {
    @apply scale-[98%];
    @apply bg-gray-200;
    @apply text-gray-50;
  }
}
/* keyboard navigatioin */
.react-calendar button:not([disabled]):focus-visible,
.react-calendar__navigation__label:not([disabled]):focus-visible {
  @apply scale-[104%];
}




.react-calendar__viewContainer {
  @apply p-2;
}

.react-calendar__tile {
  @apply py-1.5 border border-transparent;
}


.react-calendar__tile--now {
  @apply font-semibold bg-gray-100;
}

.react-calendar__tile--active {
  @apply text-gray-50 bg-secondary font-semibold;
}
/* Century & Decade View */

.react-calendar__century-view__decades {

}

.react-calendar__tile.react-calendar__century-view__decades__decade,
.react-calendar__tile.react-calendar__decade-view__years__year {
  /* @apply py-2; */
}



/* Month View */
.react-calendar__month-view__weekdays {
  text-align: center;
  @apply uppercase font-compressed font-bold text-sm pb-2;
  & abbr {
    @apply no-underline;
  }
}

/* .react-calendar__century-view,
.react-calendar__decade-view,
.react-calendar__year-view__months,
.react-calendar__month-view__days {
  @apply gap-1;
} */

.react-calendar__month-view__days__day--neighboringMonth {
  @apply text-gray-200;
}




