body.ReactModal__Body--open {
  overflow: hidden !important;
}

.ReactModal__Overlay {
  &:before {
    content: "";
    @apply absolute inset-0;
    @apply bg-gray-100 opacity-90;
  }
}
.ReactModal__Overlay--primary {
  &:before {
    background-color: transparent;
    background-image: linear-gradient(theme("colors.primary.DEFAULT") 0%, theme("colors.primary.variant.DEFAULT") 100%);
    @apply opacity-90;
  }
}

.ReactModal__Content {
  
}


.modal-inner-content {
  @apply overflow-y-auto shadow-lg bg-gray-50 rounded-lg relative;
  @apply p-4 lg:p-6;
  max-height: calc(100dvh - max(env(safe-area-inset-top), 1rem) - max(env(safe-area-inset-bottom), 1rem));
  margin-left: max(env(safe-area-inset-left), 1rem);
  margin-right: max(env(safe-area-inset-right), 1rem);
}
