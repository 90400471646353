@layer components {
  .interactive-cell {
    @apply duration-fast;
    @apply rounded;
    -webkit-tap-highlight-color: transparent;
  }
  /* mobile */
  .interactive-cell:not([disabled]):active {
    @apply scale-[99%];
  }
  
  /* desktop */
  @media (hover:hover) {
    .interactive-cell:not([disabled]):hover {
      @apply scale-[101%];
      @apply bg-gray-150/30;
    }
    .interactive-cell:not([disabled]):active {
      @apply scale-[99%];
    }
  }
  /* keyboard navigatioin */
  .interactive-cell:not([disabled]):focus-visible {
    @apply scale-[101%];
    @apply bg-gray-150/30;
  }
  
}
