@layer components {
  .r-select {
    & .r-select__control {
      @apply flex w-full px-3 py-1.5 border border-gray-900 hover:border-gray-900 bg-transparent rounded;
    }

    & .r-select__placeholder {
      @apply text-gray-200;
      @apply text-gray-200;
    }

    & .r-select__single-value {
      @apply text-gray-900
    }

    & .r-select__indicators {
      @apply text-gray-900;
    }

    &.r-select--focus .r-select__control {
      @apply outline-none ring-1 ring-secondary border-secondary;
    }
    &.r-select--focus ~ .peer-focus\:text-secondary {
      @apply text-secondary;
    }
    & .r-select__option--is-focused {
      @apply bg-gray-100;
    }
    & .r-select__option--is-selected {
      @apply bg-secondary;
    }

    & .r-select__indicator {
      @apply rounded-full;
      @apply cursor-pointer;
      @apply aspect-square;
      @apply items-center;
      &:not([disabled]):active {
        @apply scale-90;
        @apply bg-gray-200;
        @apply text-gray-50;
      }
      
      /* desktop */
      @media (hover:hover) {
        &:not([disabled]):hover {
          @apply scale-110;
          @apply bg-gray-100;
          @apply text-current;
        }
        &:not([disabled]):active {
          @apply scale-90;
          @apply bg-gray-200;
          @apply text-gray-50;
        }
      }
      
      /* keyboard navigation */
      &:not([disabled]):focus-visible {
        @apply scale-110;
      }
    }

    & .r-select__dropdown-indicator {
      @apply m-0 p-0 px-2 text-current;
    }

    & .r-select__clear-indicator {
      @apply text-current;
    }

    & .r-select__input-container {
      @apply p-0 m-0;
      @apply text-gray-900
    }

    & .r-select__value-container {
      @apply m-0 p-0;
    }

    & .r-select__multi-value {
      @apply bg-gray-200/20;
    }
    & .r-select__multi-value__label {
      @apply text-gray-900;
    }


    & .r-select__menu {
      @apply rounded;
      @apply bg-surface;
      @apply shadow-control-overlay;
      @apply border-0;
    }
  }


}


.dropdown-select {
  
  & .dropdown-select__control {
    @apply flex border-gray-900 border rounded cursor-pointer font-condensed font-semibold px-0;
  }
  & .dropdown-select__control--is-focused {
    @apply outline-none ring-1 ring-secondary border-secondary;
  }

  & .dropdown-select__value-container {
    @apply py-0 pb-px;
  }

  & .dropdown-select__single-value {
    @apply text-gray-900;
  }
  

  & .dropdown-select__indicator {
    @apply text-gray-900;
  }

  & .dropdown-select__input-container {
    @apply p-0
  }

  & .dropdown-select__option--is-focused {
    @apply bg-gray-100;
  }
  & .dropdown-select__option--is-selected {
    @apply bg-secondary;
  }

  & .dropdown-select__menu {
    @apply rounded;
    @apply bg-surface;
    @apply shadow-control-overlay;
    @apply border-0;
  }
}


.team-tag-select {
  & .dropdown-select__control {
    @apply bg-gray-900 text-sm;
  }
  & .dropdown-select__value-container {
    @apply px-1
  }
  & .dropdown-select__single-value {
    @apply text-gray-50 font-bold uppercase;
  }
  & .dropdown-select__indicator {
    @apply text-gray-50 py-0 px-1;
    & svg {
      @apply w-3 h-3
    }
  }
}
