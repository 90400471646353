.tab-bar {
  @apply flex overflow-x-auto min-w-0;
  @apply whitespace-nowrap;
  @apply snap-x;
}

.tab-bar__tab {
  @apply duration-fast;
  @apply px-2 font-bold font-compressed uppercase;
  @apply rounded;
  @apply text-gray-200;
  @apply select-none;
  @apply snap-start;
  -webkit-tap-highlight-color: transparent;
  &:first-child {
    @apply ml-2;
  }
  &:last-child {
    @apply mr-2;
  }
}

.tab-bar__tab-content {
  @apply py-1.5;
  @apply duration-fast;
  @apply origin-bottom;
  @apply relative;
  &:after {
    content: "";
    @apply duration-fast;
    @apply absolute bottom-0 left-0 right-0;
    @apply bg-transparent;
    height: 2px;
  }
}

.tab-bar__tab--active {
  @apply text-gray-900;
  & .tab-bar__tab-content:after {
    @apply bg-team;
  }
}

.tab-bar__tab:not([disabled]) {
  &:active {
    @apply bg-gray-200;
    @apply rounded-t;
    & .tab-bar__tab-content {
      @apply scale-95;
      @apply text-gray-50;
      &:after {
        @apply -mx-2;
        height: 100%;
        z-index: -1;
      }
    }
  }
}

@media (hover:hover) {
  .tab-bar__tab:not([disabled]) {
    &:hover {
      & .tab-bar__tab-content {
        @apply scale-105;
        @apply text-gray-900;
        &:after {
          @apply bg-gray-900;
          @apply -mx-1;
        }
      }
    }
    &:active {
      @apply bg-transparent;
      @apply rounded-none;
      & .tab-bar__tab-content {
        @apply scale-95;
        @apply text-gray-900;
        &:after {
          @apply rounded-none;
          @apply mx-0;
          height: 2px
        }
      }
    }
  }
}
.tab-bar__tab:not([disabled]):focus-visible .tab-bar__tab-content {
  @apply scale-105;
  @apply text-gray-900;
}
