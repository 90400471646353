@layer components {

  a, button {
    @apply outline-current outline-offset-2;
  }
  
  a:not([disabled]):focus-visible, button:not([disabled]):focus-visible {
    @apply outline-dotted outline-1;
  }

  
  .button {
    @apply relative;
    @apply overflow-hidden;
    @apply py-2.5 px-4;
    @apply rounded;
    @apply font-semibold font-condensed;
    @apply items-baseline;
    @apply duration-fast;
    @apply no-underline;
    @apply select-none;
    @apply whitespace-nowrap;
    @apply inline-flex items-center;
    -webkit-tap-highlight-color: transparent;
  }
  
  .button--inline,
  .button.inline {
    @apply p-0;
  }
  
  .button svg {
    width: 1em;
    height: 1em;
  }
  
  .button:before {
    content: '\00a0';
    visibility: hidden;
    text-indent: -999em;
    display: inline-block;
    width: 0;
  }
  
  .button[disabled] {
    @apply opacity-50;
    @apply cursor-default;
    @apply pointer-events-none;
    @apply outline-none;
  }

  .button__icon {
    margin-left: 0.25em;
  }

  .button__icon--left {
    margin-right: 0.25em;
  }
  
  /* mobile */
  .button:not([disabled]):active {
    @apply scale-[98%];
  }
  
  /* desktop */
  @media (hover:hover) {
    .button:not([disabled]):hover {
      @apply scale-[102%];
    }
    .button:not([disabled]):active {
      @apply scale-[98%];
    }
  }
  /* keyboard navigatioin */
  .button:not([disabled]):focus-visible {
    @apply scale-[102%];
  }
  
  
  
  /* .button.cursor-not-allowed:disabled, 
  .button.cursor-not-allowed[disabled] {
    @apply cursor-not-allowed;
  } */
  
  .button.flex {
    display: flex;
  }

  /* PRIMARY */
  .button--primary {
    @apply text-white-forced;
    @apply bg-primary;
    @apply outline-primary;
  }
  @media (hover:hover) {
    .button--primary:not([disabled]):hover,
    .button--primary:not([disabled]):focus-visible {
      @apply bg-primary-variant;
    }
  }
  .button--primary:not([disabled]):active{
    @apply bg-primary-variant-strong;
  }


  /* OUTLINE-PRIMARY */
  .button--outline-primary {
    @apply text-primary;
    @apply border border-primary;
    @apply outline-primary;
  }
  
  /* PRIMARY-VARIANT-STRONG */
  .button--primary-variant-strong {
    @apply text-white-forced;
    @apply bg-primary-variant-strong;
    @apply outline-primary-variant-strong;
  }
  @media (hover:hover) {
    .button--primary-variant-strong:not([disabled]):hover,
    .button--primary-variant-strong:not([disabled]):focus-visible {
      background-color: #830606;
    }
  }
  .button--primary-variant-strongr:not([disabled]):active{
    @apply bg-primary-variant-strong;
  }


  /* SECONDARY  */
  .button--secondary {
    @apply text-white-forced;
    @apply outline-secondary;
    background-color: var(--secondary);
    @apply bg-secondary
  }
  @media (hover:hover) {
    .button--secondary:not([disabled]):hover,
    .button--secondary:not([disabled]):focus-visible {
      @apply bg-secondary-darker;
    }
  }
  .button--secondary:not([disabled]):active{
    @apply bg-secondary;
  }

  /* Accepted  */
  .button--accepted {
    @apply text-white-forced;
    @apply outline-accepted;
    @apply bg-accepted;
  }

  /* OUTLINE-Accepted */
  .button--outline-accepted {
    @apply text-accepted;
    @apply border border-accepted;
    @apply outline-accepted;
  }
  @media (hover:hover) {
    .button--accepted:not([disabled]):hover,
    .button--accepted:not([disabled]):focus-visible {
      /* @apply bg-accepted-darker; */
    }
  }
  .button--accepted:not([disabled]):active{
    @apply bg-accepted;
  }

  /* MAYBe  */
  .button--maybe {
    @apply text-white-forced;
    @apply outline-maybe;
    @apply bg-maybe;
  }
  @media (hover:hover) {
    .button--maybe:not([disabled]):hover,
    .button--maybe:not([disabled]):focus-visible {
      /* @apply bg-maybe-darker; */
    }
  }
  .button--maybe:not([disabled]):active{
    @apply bg-maybe;
  }

  /* DECLINED  */
  .button--declined {
    @apply text-white-forced;
    @apply outline-declined;
    @apply bg-declined;
  }
  @media (hover:hover) {
    .button--declined:not([disabled]):hover,
    .button--declined:not([disabled]):focus-visible {
      /* @apply bg-declined-darker; */
    }
  }
  .button--declined:not([disabled]):active{
    @apply bg-declined;
  }


  
  .button--outline-secondary {
    @apply text-secondary;
    @apply border border-secondary;
    @apply outline-secondary;
  }
  
  .button--link-secondary {
    @apply text-secondary;
  }
  
  .button--link-secondary:not([disabled]):active {
    @apply text-gray-900;
  }
  
  @media (hover:hover) {
    .button--link-secondary:not([disabled]):hover {
      @apply text-gray-900;
    }
  }
  .button--link-secondary:not([disabled]):focus-visible {
    @apply text-gray-900;
  }
  
  
  
  
  .button--link-muted {
    @apply text-gray-200;
    @apply font-normal;
  }
  
  .button--link-muted:not([disabled]):active {
    @apply text-secondary;
  }
  
  @media (hover:hover) {
    .button--link-muted:not([disabled]):hover {
      @apply text-secondary;
    }
  }
  .button--link-muted:not([disabled]):focus-visible {
    @apply text-secondary;
  }
  
  .button--link-dark {
    @apply text-gray-900;
    @apply font-normal;
  }
  
  .button--link-dark:not([disabled]):active {
    @apply text-secondary;
  }
  
  @media (hover:hover) {
    .button--link-dark:not([disabled]):hover {
      @apply text-secondary;
    }
  }
  .button--link-dark:not([disabled]):focus-visible {
    @apply text-secondary;
  }
  
  .button--outline-dark {
    @apply text-gray-900;
    @apply border border-gray-900;
    @apply outline-gray-900;
  }
  
  .button--accent {
    @apply text-white-forced;
    @apply bg-accent;
    @apply outline-accent;
  }
  @media (hover:hover) {
    .button--accent:not([disabled]):hover,
    .button--accent:not([disabled]):focus-visible {
      @apply bg-accent-variant;
    }
  }
  .button--accent:not([disabled]):active{
    @apply bg-accent-variant-strong;
  }
  
  .button--muted {
    @apply text-white-forced;
    @apply bg-muted;
    @apply outline-muted;
  }
  @media (hover:hover) {
    .button--muted:not([disabled]):hover,
    .button--muted:not([disabled]):focus-visible {
      @apply bg-muted-variant;
    }
  }
  .button--muted:not([disabled]):active{
    @apply bg-muted-variant-strong;
  }


}
