.nav-px {
  @apply pl-max-5-or-safe-area-inset-left pr-max-5-or-safe-area-inset-right;
  @screen lg {
    @apply pl-max-6-or-safe-area-inset-left pr-max-6-or-safe-area-inset-right;
  }
}



.team-nav-item {
  @apply duration-fast relative;
  @apply flex items-center  px-4 border-b;
  @apply gap-2;
  @apply py-3;
  @apply font-semibold border-gray-150;
  @apply select-none;
  @apply whitespace-nowrap text-ellipsis overflow-hidden;
  -webkit-tap-highlight-color: transparent;
}

.team-nav-item:last-child {
  @apply border-b-0;
}

.team-nav-item:not([disabled]):active {
  @apply bg-gray-100;
  @apply scale-95;
}
@media (hover:hover) {
  .team-nav-item:not([disabled]) {
    @apply origin-left;
    &:hover {
      @apply bg-gray-100;
      @apply scale-105;
    }
    &:active {
      @apply bg-gray-150;
      @apply scale-100;
    }
  }
}
.team-nav-item:not([disabled]):focus-visible {
  @apply bg-gray-100;
  @apply scale-105;
}




.main-nav-item {
  @apply block;
  @apply font-semibold;
  @apply select-none;
  -webkit-tap-highlight-color: transparent;
  & svg {
    width: 1em;
    height: 1em;
  }
}
@screen lg {
  /* .main-nav-item--active {
    &:before {
      content: "";
      @apply absolute;
      left: 0;
      @apply -ml-2;
      @apply w-1 h-6;
      @apply bg-team rounded-r;
    }
  } */
}

.main-nav-item:not([disabled]):active .main-nav-item__label {
  @apply bg-gray-150;
  & .main-nav-item__label__content {
    @apply scale-95;
  }
}
@media (hover:hover) {
  .main-nav-item:not([disabled]) {
    &:hover {
      & .main-nav-item__label {
        @apply bg-gray-150;
      }
      & .main-nav-item__label__content {
        @apply scale-105;
      }
    }
    &:active {
      & .main-nav-item__label {
        @apply bg-gray-200;
      }
      & .main-nav-item__label__content {
        @apply scale-100;
      }
    }
  }
}
.main-nav-item:not([disabled]):focus-visible .main-nav-item__label {
  & .main-nav-item__label {
    @apply bg-gray-150;
  }
  & .main-nav-item__label__content {
    @apply scale-105;
  }
}

.main-nav-item {
  /* @apply py-0.5; */
  @apply origin-left;
  @apply border-t border-b border-gray-150;
  @apply mx-0;
  @apply -mt-px;
  &:first-child {
    @apply mt-0;
  }
}

.main-nav-item__label {
  @apply px-4 py-2.5;
  @apply -my-px -mx-4;
  @apply rounded-lg;
  @apply relative;
  @apply duration-fast;
  @apply flex justify-between items-center;
}

.main-nav-item__label__content {
  @apply flex items-center;
  @apply gap-2;
  @apply duration-fast;
}

.main-nav-item.main-nav-item.main-nav-item--active {
  & .main-nav-item__label {
    @apply bg-surface;
    &:after {
      content: "";
      @apply absolute;
      @apply left-px top-px bottom-px;
      @apply w-4;
      @apply border-l-team border-l-4;
      @apply pointer-events-none;
      @apply rounded-l-lg;
    }
  }
}



.secondary-nav {
  @apply overflow-hidden;
}


.secondary-nav-item {
  @apply flex;
  @apply border-b border-t border-gray-150;
  @apply -mt-px;
  @apply select-none;
  -webkit-tap-highlight-color: transparent;
  &:first-child {
    @apply border-t-0 mt-0;
  }
}

.secondary-nav-item:not([disabled]):active {
  & .secondary-nav-item__label__content {
    @apply scale-95;
  }
  & .secondary-nav-item__label {
    @apply bg-gray-150;
  }
}

@media (hover:hover) {
  .secondary-nav-item:not([disabled]) {
    &:hover {
      & .secondary-nav-item__label {
        @apply bg-gray-150;
      }
      & .secondary-nav-item__label__content {
        @apply scale-105;
      }
    }
    &:active {
      & .secondary-nav-item__label {
        @apply bg-gray-200;
      }
      & .secondary-nav-item__label__content {
        @apply scale-100;
      }
    }
  }
}
.secondary-nav-item:not([disabled]):focus-visible {
  & .secondary-nav-item__label {
    @apply bg-gray-150;
  }
  & .secondary-nav-item__label__content {
    @apply scale-105;
  }
}

@screen lg {
  .secondary-nav-item--active {
    & .secondary-nav-item__label {
      @apply bg-surface rounded-lg;
      @apply font-semibold;
      &:before {
        content: "";
        @apply absolute;
        @apply left-px top-px bottom-px;
        @apply w-4;
        @apply border-l-team border-l-4 rounded-l-lg;
      }
    }
  }
}

.secondary-nav-item__label {

  @apply rounded-lg;
  @apply flex;
  @apply relative;
  @apply py-2 px-4;
  @apply duration-fast;
  @apply flex-1;
  @apply -my-px -mx-4;
}
  
.secondary-nav-item__label__content {
  @apply duration-fast;
  @apply origin-left;
}


.secondary-nav-item--lv-2 {
  @apply pl-9 lg:pl-10;  
}





.nav-user {
  @apply flex flex-col lg:flex-row lg:gap-1 items-center;
  -webkit-tap-highlight-color: transparent;
}

.nav-user__img {
  @apply duration-fast;
  @apply rounded-full w-8 h-8 lg:h-6 lg:w-6 lg:order-2;
}

.nav-user__title {
  @apply duration-fast;
  @apply font-compressed text-sm lg:order-1 text-primary;
}

.nav-user__title--name {
  @apply max-w-[4.3rem] lg:max-w-[10rem] overflow-hidden text-ellipsis;
  @apply text-muted;
}


.nav-user:active {
  & .nav-user__img {
    @apply scale-90;
  }
  & .nav-user__title {
    @apply text-gray-900;
  }
}
@media (hover:hover) {
  .nav-user {
    &:hover {
      & .nav-user__img {
        @apply scale-110;
      }
      & .nav-user__title {
        @apply text-gray-900;
      }
    }
    &:active {
      & .nav-user__img {
        @apply scale-100;
      }
    }
  }
}
.nav-user:focus-visible {
  & .nav-user__img {
    @apply scale-110;
  }
  & .nav-user__title {
    @apply text-gray-900;
  }
}
